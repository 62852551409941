hljs-comment,
.hljs-quote {
	color: #586e75;
	font-style: italic;
}

.hljs-keyword {
	color: #ff9500;
}
.hljs-selector-tag {
	color: #ffbb00;
}
.hljs-literal {
	color: #ff6200;
}
.hljs-addition {
	color: #ff3700;
}

.hljs-number {
	color: #00b7ff;
}
.hljs-string {
	color: #00bd74;
}
.hljs-doctag {
	color: #00bd97;
}
.hljs-regexp {
	color: #00adbd;
}

.hljs-title {
	color: #aa00bd;
}
.hljs-section {
	color: #7b00bd;
}
.hljs-built_in {
	color: #5b00bd;
}
.hljs-name {
	color: #3c00bd;
}

.hljs-variable {
	color: #00bd9e;
}
.hljs-template-variable {
	color: #00bd6e;
}
.hljs-class .hljs-title {
	color: #8ebd00;
}
.hljs-type {
	color: #00bd03;
}
.hljs-tag {
	color: #00bd03;
}

.hljs-attribute {
	color: #55bd00;
}

.hljs-symbol,
.hljs-bullet,
.hljs-link,
.hljs-subst,
.hljs-meta {
	color: #cb4b16;
}

.hljs-deletion {
	color: #dc322f;
}

.hljs-selector-id,
.hljs-selector-class {
	color: #d3a60c;
}

.hljs-formula {
	background: #073642;
}

.hljs-emphasis {
	font-style: italic;
}

.hljs-strong {
	font-weight: bold;
}
