/* `(?<!\$scale \*) ([0-9]+px)`
   ` $scale * $1`
----------------------
  `\$scale \* 1px`
  `1px` */
$scale: 0.5;
:export {
	scale: $scale;
}

.error {
	color: red;
}

@supports (-webkit-overflow-scrolling: touch) {
	textarea {
		transform: translate(-3px, 0); // ios only textarea fix
	}
}

@font-face {
	font-family: "WorkflowGlyphs";
	src: local("WorkflowGlyphs.ttf"), url(./WorkflowGlyphs.ttf) format("ttf");
	src: local("WorkflowGlyphs-Regular.woff"),
		url(./WorkflowGlyphs-Regular.woff) format("woff");
	src: local("WorkflowGlyphs-Regular.woff2"),
		url(./WorkflowGlyphs-Regular.woff2) format("woff2");
}

.segmentedbutton {
	background-color: #eeeeee;
	min-height: $scale * 58px;
	border-radius: $scale * 15px;
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	overflow: hidden;
	input {
		position: absolute;
		left: -9999px;
	}
	label {
		border: none;
		background-color: transparent;
		font-size: $scale * 22px;
		margin: $scale * 8px;
		padding: 0;
		border-radius: $scale * 15px;
		transition: 0.1s box-shadow, 0.1s background-color, 0.1s margin,
			0.1s padding;
		text-align: center;
		vertical-align: middle;
		cursor: pointer;
		display: grid;
		grid-template-rows: 1fr [center] max-content 1fr;
		position: relative;
		& > div {
			grid-row: center;
			padding-left: $scale * 8px;
			padding-right: $scale * 8px;
		}
		&:after,
		&:before {
			content: "";
			width: 1px;
			top: $scale * 6px;
			bottom: $scale * 6px;
			position: absolute;
			transition: 0.1s background-color;
		}
		&.rightline:after {
			background-color: #d6d6d8;
		}
		&:after {
			right: $scale * -8px;
		}
		&.leftline:before {
			background-color: #d6d6d8;
		}
		&:before {
			left: $scale * -8px;
		}
	}
	input:checked + label {
		margin: $scale * 4px;
		padding: $scale * 4px;
		-webkit-text-stroke-width: 0.5px; // supported on everything but ie for some reason
		-webkit-text-stroke-color: black;
		background-color: white;
		box-shadow: 0 $scale * 4px $scale * 4px rgba(0, 0, 0, 0.2);
	}
	input:checked:focus + label {
		box-shadow: 0 $scale * 4px $scale * 4px rgba(0, 0, 0, 0.2),
			0 0 $scale * 15px rgba(0, 0, 255, 0.2);
	}
	input:focus + label {
		box-shadow: 0 0 $scale * 15px rgba(0, 0, 255, 0.2);
	}
}

@mixin vertical-center {
	display: grid;
	grid-template-rows: 1fr [center] max-content 1fr;
	& > div {
		grid-row: center;
	}
}

.cssdemo {
	background-color: #efeff2;
	padding: $scale * 50px;
	font-family: sans-serif;
	.shortcutnameicon {
		font-family: WorkflowGlyphs;
		font-size: $scale * 198px;
		padding: $scale * 50px;
		border-radius: $scale * 25px;
		color: white;
		display: inline-block;
		&.c3871DE {
			background: linear-gradient(to bottom, #737ec4, #6570b9);
		}
		&.cFEC418 {
			background: linear-gradient(to bottom, #d69c2f, #cd8d26);
		}
	}
	.icon {
		width: $scale * 40px;
		height: $scale * 40px;
		display: inline-block;

		background-image: url(https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Red_x.svg/600px-Red_x.svg.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		transition: 0.1s transform ease-in-out;
		&.delete {
			background-image: url(https://i.imgur.com/08e6nna.png);
		}
		&.remove {
			width: $scale * 36px;
			height: $scale * 36px;
			background-image: url(https://i.imgur.com/z80ie9S.png);
		}
		&.add {
			width: $scale * 36px;
			height: $scale * 36px;
			background-image: url(https://i.imgur.com/KLnbRqD.png);
		}
		&.reorder {
			background-image: url(https://i.imgur.com/y5mqo8Q.png);
		}
		&.expandopen {
			background-image: url(https://i.imgur.com/XoAO3tw.png);
		}
		&.expandclosed {
			background-image: url(https://i.imgur.com/XoAO3tw.png);
			transform: rotate(-90deg);
		}
	}
	button.icon {
		cursor: pointer;
		border: none;
	}
	.connector {
		margin-top: $scale * 50px; // !!!!! EXACT VALUE NOT KNOWN
	}
	@for $i from 0 through 4 {
		.indent#{$i} {
			margin-left: $scale * 40px * $i;
		}
	}
	.action {
		background-color: white;
		padding: $scale * 24px;
		padding-bottom: 0;
		border-radius: $scale * 28px;
		box-shadow: 0 0 $scale * 20px rgba(0, 0, 0, 0.1);
		overflow-x: hidden;
		overflow-y: hidden;
		word-wrap: break-word;
		&.comment {
			background: linear-gradient(
				to bottom,
				#f8ecd0 88px * $scale,
				#e9d2a1 88px * $scale,
				#e9d2a1 90px * $scale,
				#fbf5e8 90px * $scale
			);
		}
		.codedisplay {
			width: 100%;
			resize: vertical;
		}
		.dictionaryparameter {
			position: relative;
			overflow-y: hidden;
			overflow-x: hidden;
			margin-left: -24px * $scale;
			margin-right: -24px * $scale;
			.dropplaceholder {
				height: $scale * 88px;
			}
			.clickawayhandler {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
		.parameter {
			display: grid;
			margin-left: $scale * 10px;
			grid-template-columns: [label] max-content 1fr [value] max-content;
			grid-template-rows: 1fr [center] minmax($scale * 58px, max-content) 1fr;
			padding-top: $scale * 16px;
			padding-bottom: $scale * 15px;
			position: relative;
			transition: 0.1s opacity ease-in-out, 0.1s max-height ease-in-out,
				0.1s padding ease-in-out;
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 1px;
				background-color: #dbdbdb;
			}
			&::after {
				content: "";
				position: absolute;
				bottom: -1px;
				left: 0;
				right: 0;
				height: 1px;
				background-color: #dbdbdb;
			}
			&.dictionary {
				margin-left: 0;
				padding-top: $scale * 15px;
				grid-template-columns: $scale * 34px [remove] max-content $scale * 27px [key] max-content 4fr [line] 1px $scale * 20px [value] max-content 6fr [reorder] max-content $scale * 24px;
				transition: 0.1s box-shadow, 0.1s transform, 0.1s opacity,
					0.1s max-height ease-in-out, 0.1s padding ease-in-out;
				background-color: white;
				max-height: $scale * 88px;
				&.static {
					transition: 0.1s box-shadow, 0.1s opacity, 0.1s max-height ease-in-out,
						0.1s padding ease-in-out;
				}
				&.dropping {
					z-index: 1;
				}
				&:not(.dragging)::before {
					left: 32px * $scale;
				}
				&:not(.dragging)::after {
					left: 32px * $scale;
				}
				&.dragging {
					box-shadow: 0 0 $scale * 15px rgba(0, 0, 0, 1);
					opacity: 0.8;
					z-index: 1;
					cursor: move;
					transition: 0.1s box-shadow;
					touch-action: manipulation;
				}
				&.removing {
					transform: translate(-200px * $scale, 0);
				}
				&.removingnow {
					transform: scale(1, 0);
				}
				&.aboveremovingnow {
					transform: translate(0, -88px * $scale);
				}
				.deletebtn {
					position: absolute;
					right: -200px * $scale;
					max-width: 200px * $scale;
					width: 200px * $scale;
					top: 0;
					bottom: 0;
					border: none;
					background-color: #fe3c30;
					color: white;
					display: none;
				}
				.deletebtn.deleting {
					display: block;
				}
				.remove {
					grid-column: remove;
					grid-row: center;
					font-size: $scale * 28px;
					@include vertical-center;
				}
				.line {
					grid-column: line;
					background-color: #dbdbdb;
					// height: 100%;
					top: 0;
					bottom: 0;
					left: 0;
					width: 1px;
					position: absolute;
				}
				.key {
					grid-column: key;
					grid-row: center;
					font-size: $scale * 28px;
					@include vertical-center;
				}
				.value {
					grid-column: value;
					grid-row: center;
					font-size: $scale * 28px;
					@include vertical-center;
				}
				.reorder {
					grid-column: reorder;
					grid-row: center;
					font-size: $scale * 28px;
					@include vertical-center;
					cursor: move;
					touch-action: manipulation;
				}
			}
			&.addnewfield {
				grid-template-columns: $scale * 24px [add] max-content $scale * 27px [description] max-content 1fr $scale * 24px;
				&:not(.dragging)::before {
					left: 32px * $scale;
				}
				&:not(.dragging)::after {
					left: 32px * $scale;
				}
				.add {
					grid-column: add;
					grid-row: center;
					font-size: $scale * 28px;
					@include vertical-center;
				}
				.description {
					grid-column: description;
					grid-row: center;
					font-size: $scale * 28px;
					color: #aaaaaa;
					@include vertical-center;
				}
			}
			&.showmore {
				color: #8a8a8d;
				margin-left: $scale * -24px;
				margin-right: $scale * -24px;
				grid-template-columns: $scale * 34px [label] max-content 1fr [value] max-content $scale * 24px;
			}
			.label {
				grid-column: label;
				grid-row: center;
				font-size: $scale * 28px;
				@include vertical-center;
			}
			.value {
				grid-column: value;
				grid-row: center;
				display: grid;
				@include vertical-center;
			}

			&.revealing {
				opacity: 0;
				max-height: 0;
			}
			&.collapsing {
				opacity: 0;
				max-height: 0;
				padding-top: 0 !important;
				padding-bottom: 0 !important;
			}
			&.collapsed {
				display: none;
			}
			&.visible {
				opacity: 1;

				max-height: $scale * 58px !important;
				&.size604 {
					max-height: $scale * 604px !important;
				}
				&.size243 {
					max-height: $scale * 243px !important;
				}
			}

			&.multilinetext {
				padding-top: $scale * 30px;
				.textpreview {
					position: absolute;
					height: 100%;
					width: 100%;
					pointer-events: none;
					overflow: hidden;
					font-size: $scale * 28px;
					white-space: pre;
					&.text {
						font-family: sans-serif;
					}
					&.code {
						font-family: monospace;
					}
					pre {
						margin: 0;
					}
				}
				.texteditor {
					padding: 0;
					margin: 0;
					position: absolute;
					height: 100%;
					width: 100%;
					border: 0;
					background: transparent;
					-webkit-text-fill-color: transparent;
					-webkit-overflow-scrolling: touch;

					font-size: $scale * 28px;
					&.text {
						font-family: sans-serif;
					}
					&.code {
						font-family: monospace;
					}
				}
				height: 100000px;
			}
		}
		.title {
			margin: 0;
			text-transform: uppercase;
			color: #949496;
			font-size: $scale * 23px;
			display: grid;
			padding-bottom: $scale * 27px;
			grid-template-columns: [icon] max-content $scale * 16px [title] max-content 1fr [delete] max-content;
			.actionicons {
				grid-column: delete;
			}
			.titletext {
				grid-column: title;
				display: grid;
				grid-template-rows: 1fr max-content 1fr;
				> div {
					grid-row: 2;
					font-weight: lighter;
				}
			}
		}
		.parametersummary {
			padding-bottom: $scale * 27px;
			padding-left: $scale * 10px;
			font-size: $scale * 31px;
			font-weight: 500;
			.input {
				background-color: rgba(0, 105, 240, 0.1);
				border-radius: $scale * 9999px;
				border: none;
				display: inline;
				color: #007aff;
				font-weight: medium;
				padding-left: $scale * 14px;
				padding-right: $scale * 14px;
				line-height: $scale * 53px;
				font: inherit;
				.placeholder {
					color: rgba(0, 122, 255, 0.5);
				}
			}
		}
	}
}
